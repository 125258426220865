import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import StyledList from '../../atoms/StyledList'
import LaunchButton from '../../atoms/LaunchButton'
import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
`
const StyledDescription = styled(HomeSectionDescription)`
  margin-bottom: 16px;
`

const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const size = {
  lg: {
    height: '890'
  },
  xl: {
    height: '840'
  },
  xxl: {
    height: '780'
  }
}

const FiscalPropertyTax = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "fiscal-lmnp.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      right={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h2'>
        Le dispositif Censi-Bouvard, ou LMNP
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Le dispositif Censi-Bouvard est prévu pour l’investissement locatif dans les résidences séniors ou étudiantes.
      </HomeSectionSubtitle>
      <StyledDescription>
        L’objectif est d’investir dans un appartement meublé au sein d’une résidence pour séniors ou d’une résidence étudiante, en s’engageant à louer le bien. De cette manière, l’acheteur peut bénéficier du cadre fiscal LMNP, à savoir le cadre des loueurs en meublé non professionnels.
      </StyledDescription>
      <StyledDescription>
        Le dispositif Censi-Bouvard comporte notamment 3 avantages en termes de fiscalité de l’immobilier neuf&nbsp;:
      </StyledDescription>
      <StyledList>
        <li>
          <strong>Récupération totale</strong> de la TVA au bout de 20 ans
        </li>
        <li>
          <strong>Réduction des impôts</strong> de 11%
        </li>
        <li>
          <strong>Cadre fiscal avantageux</strong> qui permet de déduire les charges liées à l’investissement du bien et celles liées à l’investissement dans le mobilier de l’appartement.
        </li>
      </StyledList>
      <StyledDescription>
        À noter que pour pouvoir bénéficier du statut LMNP, il est nécessaire de louer l’appartement pendant minimum 9 ans.
      </StyledDescription>
      <StyledDescription>
        N’attendez pas trop longtemps si vous souhaitez investir pour bénéficier du dispositif Censi-Bouvard, celui-ci n’est pour l’instant valable que jusqu’à la <strong>fin de l’année 2022.</strong>
      </StyledDescription>
      <BottomText>
        <strong>Vous souhaitez investir dans l’immobilier neuf ?</strong>
      </BottomText>
      <ButtonContainer>
        <LaunchButton
          link='/nous-contacter'
          title='Contactez-nous' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default FiscalPropertyTax
