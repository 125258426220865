import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import FiscalHero from '../../molecules/FiscalHero'
import TopSection from '../../molecules/TopSection'
import FiscalImage from '../../molecules/FiscalImage'

const StyledFiscalImage = styled(FiscalImage)`
  z-index: -1;
  display: none;

  ${media.greaterThan('xl')`
    display: block;
    height: 600px; 
    top: 210px;
  `}
`

const FiscalTopSection = () => (
  <TopSection component={StyledFiscalImage}>
    <FiscalHero />
  </TopSection>
)

export default FiscalTopSection
