import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import StyledList from '../../atoms/StyledList'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 830px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const StyledDescription = styled(HomeSectionDescription)`
  margin-top: 16px;
`

const FiscalHousingTax = () => (
  <Container>
    <HomeSectionTitle>
      La taxe d’habitation, un impôt voué à disparaître
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      La fiscalité de l’immobilier comprend tout d’abord la taxe d’habitation.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Cet impôt est redevable par tous les propriétaires qui résident dans leur logement, ainsi que par tous les locataires. Comme son nom l’indique, cette taxe permet de couvrir l’occupation d’un logement.
      La taxe d’habitation se présente comme un impôt local. Elle est donc définie en fonction de la localisation du logement, ainsi que selon ses caractéristiques.
    </HomeSectionDescription>
    <StyledDescription>
      Ainsi, voici comment s’effectue le calcul de la taxe d’habitation&nbsp;:
    </StyledDescription>
    <StyledList>
      <li>
        <strong>Calcul sur la valeur locative nette</strong> → celle-ci est égale à la valeur locative cadastrale. Dans le cas où il s’agit d’une résidence principale, cette valeur est diminuée par des abattements obligatoires ou facultatifs en fonction de la situation fiscale&nbsp;;
      </li>
      <li>
        <strong>Addition des taux votés</strong> par la commune concernée.
      </li>
    </StyledList>
    <HomeSectionDescription>
      En tant que <strong>propriétaire d’un bien immobilier</strong>, la taxe d’habitation peut ne pas être redevable, notamment lorsque le logement est loué à une tierce personne. Quoi qu’il en soit, la taxe d’habitation est vouée à disparaître progressivement. Certains ménages ne la paient d’ailleurs plus. D’ici 2023, la taxe d’habitation sera totalement supprimée.
    </HomeSectionDescription>
  </Container>
)

export default FiscalHousingTax
