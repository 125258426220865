import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/HeroContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 840px;
  margin: auto;
  height: 600px;

  ${media.greaterThan('xl')`
    max-width: 550px;
    margin-right: 50%;
  `}
`

const FiscalHero = () => (
  <>
    <StyledContainer>
      <Header max={100}>
        Comprendre la fiscalité de l’immobilier neuf
      </Header>
      <Subtitle>
        Devenir propriétaire d’un bien immobilier implique d’être soumis à un système d’imposition particulier.
      </Subtitle>
      <Description>
        Que ce soit au niveau de la taxe d’habitation, de la taxe foncière, de l’IFI ou de vos revenus locatifs, vous êtes amenés à payer des impôts. De nombreux dispositifs vous permettent néanmoins de défiscaliser, ou du moins d’alléger vos factures fiscales, notamment dans l’immobilier neuf. Afin d’en savoir plus pour investir intelligemment, Folhomee revient sur les différents aspects de la fiscalité immobilière.
      </Description>
    </StyledContainer>
  </>
)

export default FiscalHero
