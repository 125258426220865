import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PictoTaskPaper from '../../../assets/task-paper.inline.svg'
import PictoCalculator from '../../../assets/calculator.inline.svg'
import PictoEmptyRental from '../../../assets/empty-rental.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import PictoFurnishedRental from '../../../assets/furnished-rental.inline.svg'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoFurnishedRental,
  title: 'Location meublée',
  description: 'Les revenus relèvent des bénéfices industriels et commerciaux'
}, {
  icon: PictoEmptyRental,
  title: 'Location non meublée',
  description: 'Les loyers perçus relèvent de revenus fonciers'
}]

const DETAILS_COL = [{
  icon: PictoTaskPaper,
  title: 'Régime micro-foncier ou micro-BIC',
  description: 'Ce régime permet d’obtenir un abattement forfaitaire de 30 ou 50% sur les revenus locatifs'
}, {
  icon: PictoCalculator,
  title: 'Régime réel',
  description: 'Ce régime permet de déduire les charges du propriétaire (emprunt, travaux, charges, etc) des recettes encaissées'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 830px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const StyledDescription = styled(HomeSectionDescription)`
  margin-top: 32px;
`

const StyledSubtitle = styled(HomeSectionSubtitle)`
  margin: 0;
`

const FiscalRentalTax = () => (
  <Container>
    <HomeSectionTitle>
      Les impôts liés aux revenus locatifs
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      De nombreux propriétaires sont concernés par les impôts liés aux revenus locatifs.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      En effet, l’investissement dans un bien immobilier se fait bien souvent pour en tirer les bénéfices de la location. De toute évidence, les revenus locatifs sont également <strong>soumis à la fiscalité de l’immobilier</strong>&nbsp;: tous les revenus tirés des loyers sont soumis à l’impôt sur le revenu.
    </HomeSectionDescription>
    <StyledDescription>
      Dans ce cas, il convient de distinguer deux types de locations&nbsp;:
    </StyledDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
    <StyledSubtitle>
      Sur ces deux types de location, peuvent se choisir deux régimes d’imposition distincts, à sélectionner en fonction des revenus locatifs perçus&nbsp;:
    </StyledSubtitle>
    <PictoColContainer details={DETAILS_COL}/>
    <HomeSectionDescription>
      Le régime réel s’applique de plein droit dans le cadre d’une location non meublée, lorsque le montant annuel des loyers est supérieur à 15&nbsp;000 €. Concernant la location meublée, le régime s’applique lorsque les loyers annuels dépassent les 70&nbsp;000 €.
    </HomeSectionDescription>
  </Container>
)

export default FiscalRentalTax
