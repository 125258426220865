import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const FiscalIntro = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "fiscal-intro.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  const size = {
    lg: {
      height: '500'
    },
    xxl: {
      height: '550'
    }
  }

  return (
    <ImageSection
      size={size}
      maxText='435'
      imageData={placeholderImage}>
      <HomeSectionTitle tag='h2'>
        La fiscalité de l’immobilier, comment ça marche&nbsp;?
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Dans un premier temps, il convient d’aborder les bases de la fiscalité immobilière.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        En effet, les propriétaires français doivent s’acquitter d’un certain nombre d’impôts en fonction de leur situation. Aujourd’hui, quatre impôts peuvent notamment être mentionnés&nbsp;: taxe d’habitation, taxe foncière, Impôt sur la Fortune Immobilière et impôts liés aux revenus locatifs.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default FiscalIntro
