import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '456'
  },
  xl: {
    height: '450'
  },
  xxl: {
    height: '600'
  }
}

const FiscalAdvantages = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "fiscal-advantages.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      reverse={true}
      imageData={placeholderImage}
      backgroundColor='veryLightOrange'>
      <HomeSectionTitle tag='h2'>
        La fiscalité de l’immobilier neuf&nbsp;: bénéficier d’avantages fiscaux
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        À présent que les bases de la fiscalité immobilière sont posées, il convient de s’intéresser plus précisément à la fiscalité de l’immobilier neuf.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        En investissant dans le neuf, vous pouvez effectivement bénéficier de nombreux avantages fiscaux. Loi Pinel, Censi-Bouvard…
      </HomeSectionDescription>
      <HomeSectionDescription>
        Quels dispositifs vous permettent d’optimiser votre fiscalité immobilière&nbsp;?
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default FiscalAdvantages
