import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import JsonLD from '../components/atoms/JsonLD'
import Footer from '../components/organisms/NewFooter'
import FiscalLMNP from '../components/organisms/FiscalLMNP'
import FiscalOutro from '../components/organisms/FiscalOutro'
import SiteSection from '../components/atoms/SiteSection'
import FiscalIntro from '../components/organisms/FiscalIntro'
import FiscalPinel from '../components/organisms/FiscalPinel'
import FiscalRentalTax from '../components/organisms/FiscalRentalTax'
import FiscalWealthTax from '../components/organisms/FiscalWealthTax'
import FiscalAdvantages from '../components/organisms/FiscalAdvantages'
import FiscalHousingTax from '../components/organisms/FiscalHousingTax'
import FiscalTopSection from '../components/organisms/FiscalTopSection'
import FiscalPropertyTax from '../components/organisms/FiscalPropertyTax'
import { useSiteMetadata } from '../utils/seoTools'
import { deepReplace, replaceGraphKey } from '../utils/deepReplace'

const TITLE = 'Comprendre la fiscalité de l\'immobilier neuf'
const DESCRIPTION = 'Les clés pour comprendre les particularités de la fiscalité de l\'immobilier neuf.'

const FiscalDefinition = ({ location }) => {
  const site = useSiteMetadata()
  const { webpage, organization } = deepReplace(site, replaceGraphKey)
  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: 'Folhomee',
    description: DESCRIPTION,
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: 'Programme immobilier, Folhomee, page département, recherche immobilier, accompagnement'
  }
  return (
    <>
      <SEO
        title={TITLE}
        location={location}
        description={DESCRIPTION}>
        <JsonLD>
          {([webpage, organization, linkedData])}
        </JsonLD>
      </SEO>
      <Navbar location={location} />
      <FiscalTopSection />
      <SiteSection id='fiscalité-intro' $first={true}>
        <FiscalIntro />
      </SiteSection>
      <SiteSection id='fiscalité-taxe-habitation'>
        <FiscalHousingTax />
      </SiteSection>
      <SiteSection id='fiscalité-taxe-foncière'>
        <FiscalPropertyTax />
      </SiteSection>
      <SiteSection id='fiscalité-taxe-location'>
        <FiscalRentalTax />
      </SiteSection>
      <SiteSection id='fiscalité-taxe-fortune'>
        <FiscalWealthTax />
      </SiteSection>
      <SiteSection id='fiscalité-avantages'>
        <FiscalAdvantages />
      </SiteSection>
      <SiteSection id='fiscalité-pinel'>
        <FiscalPinel />
      </SiteSection>
      <SiteSection id='fiscalité-lmnp'>
        <FiscalLMNP />
      </SiteSection>
      <SiteSection id='fiscalité-outro'>
        <FiscalOutro />
      </SiteSection>
      <Footer noImage={true} noForm={true} />
    </>
  )
}
FiscalDefinition.propTypes = {
  location: PropTypes.object.isRequired
}

export default FiscalDefinition
