import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { StaticImage } from 'gatsby-plugin-image'

import media from '../../../utils/media'
import StyledList from '../../atoms/StyledList'
import getMediaSize from '../../../utils/getMediaSize'
import useWindowSize from '../../../utils/useWindowSize'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 830px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
  padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
  padding: 0;
  `}
`
const StyledDescription = styled(HomeSectionDescription)`
  margin-top: 32px;
`
const OrangeDescription = styled(HomeSectionDescription)`
  margin-right: 4px;
  color: ${({ theme }) => get(theme, 'brightOrange', '#fff')}
`
const Inline = styled.div`
  display: flex;
  font-weight: 600;
`
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  margin-bottom: 8px;
`

const StyledHomeSectionSubtitle = styled(HomeSectionSubtitle)`
  margin: 0;
  margin-bottom: 8px; 
`

const PinelExample = () => {
  if (get(useWindowSize(), 'width') <= getMediaSize('md')) {
    return (
      <StyledList>
        <li>
          Investissement locatif d’un appartement de <strong>40m2</strong> à Lyon
        </li>
        <li>
          Avec un contrat de location sur <strong>9 ans</strong>
        </li>
        <li>
          Loyer perçu de <strong>599 €</strong> mensuels → 40m2 x plafond de loyers en zone A(13, 04 € par m2) x coefficient multiplicateur, à savoir 0, 7 + 19 = 599
        </li>
        <li>
          Réduction d’impôt estimé à <strong>5 000 €/an pendant 9 ans</strong>
        </li>
        <li>
          Cet investissement permet donc de défiscaliser <strong>18 %</strong> du prix d’achat
        </li>
      </StyledList>
    )
  }

  return (
    <>
      <StyledDescription>
        Voici un exemple&nbsp;:
      </StyledDescription>
      <ImageContainer>
        <StaticImage
          src='../../../images/fiscal-pinel.png'
          alt='Calcul de fiscalité'
          fadeIn={false} 
          loading='eager' />
      </ImageContainer>
      <Inline>
        <OrangeDescription>
          *
        </OrangeDescription>
        <StyledHomeSectionSubtitle>
          Calcul du loyer&nbsp;:
        </StyledHomeSectionSubtitle>
      </Inline>
      <HomeSectionDescription>
        • Coefficient  multiplicateur : ( 19 / surface (40m2) + 0,7 ) = 1,175
      </HomeSectionDescription>
      <HomeSectionDescription>
        • Barême Pinel : Lyon est en zone A = 13,04
      </HomeSectionDescription>
      <HomeSectionDescription>
        → Calcul du plafond de loyer 40m2 x 1,175 x 13,04 = 688,12 €
      </HomeSectionDescription>
    </>
  )
}

const FiscalPinel = () => (
  <Container>
    <HomeSectionTitle>
      La loi Pinel pour réduire ses impôts
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Le principe de la loi Pinel est relativement simple : il concerne les acheteurs d’un bien immobilier neuf éligible afin de le louer sur une durée déterminée de 6, 9 ou 12 ans.
    </HomeSectionSubtitle>
    <StyledHomeSectionDescription>
      De plus, le bien immobilier concerné par le dispositif Pinel doit être non meublé.
    </StyledHomeSectionDescription>
    <StyledHomeSectionDescription>
      Pendant la période de location du logement, le propriétaire bénéficie d’une réduction de ses impôts, en fonction de la somme investie dans le bien et de la durée d’engagement du contrat locatif.
    </StyledHomeSectionDescription>
    <StyledHomeSectionDescription>
      Globalement, la loi Pinel vous permet de profiter d’une défiscalisation pouvant aller jusqu’à 21% du prix du bien immobilier sur 12&nbsp;années.
    </StyledHomeSectionDescription>
    <PinelExample />
  </Container>
)

export default FiscalPinel
