import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import LaunchButton from '../../atoms/LaunchButton'
import ImageSection from '../../molecules/ImageSection'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const BottomText = styled(HomeSectionDescription)`
  margin-top: 64px;
  text-align: center;
`

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 24px;
`

const FiscalOutro = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "fiscal-outro.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      fixed={true}
      reverse={true}
      imageData={placeholderImage}>
      <HomeSectionTitle tag='h2'>
        En résumé, la fiscalité de l’immobilier neuf est globalement très intéressante.
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Les propriétaires peuvent bénéficier de nombreux dispositifs pour défiscaliser.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        En prime, investir dans un bien immobilier neuf permet aux propriétaires d’être exonérés pendant 2 ans de la taxe foncière. Cette exonération peut être reportée à 7 ans dans le cadre de l’achat d’un logement à hautes performances énergétiques.
      </HomeSectionDescription>
      <BottomText>
        <strong>Besoin de renseignements&nbsp;?</strong>
        <HomeSectionDescription>
          Folhomee vous accompagne dans votre projet immobilier.
        </HomeSectionDescription>
      </BottomText>
      <StyledButtonContainer>
        <LaunchButton title='Tous les biens immobiliers à vendre sont chez Folhomee' background='blue' />
      </StyledButtonContainer>
    </ImageSection>
  )
}

export default FiscalOutro
