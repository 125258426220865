import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledDescription = styled(HomeSectionDescription)`
  margin-bottom: 16px;
`

const FiscalPropertyTax = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "fiscal-property.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  const size = {
    lg: {
      height: '564'
    },
    xl: {
      height: '550'
    }
  }

  return (
    <ImageSection
      size={size}
      right={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h2'>
        La taxe foncière à payer par tous les propriétaires
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        On ne peut pas aborder la fiscalité de l’immobilier sans parler de la taxe foncière.
      </HomeSectionSubtitle>
      <StyledDescription>
        Cet impôt est redevable par tous les propriétaires d’un appartement ou d’une maison localisé en France, et ce chaque année.
      </StyledDescription>
      <StyledDescription>
        La taxe foncière est récoltée par les collectivités territoriales et permet donc de financer de nombreux services publics locaux.
      </StyledDescription>
      <StyledDescription>
        Le calcul de la taxe foncière d’un bien immobilier se fait en fonction de la valeur locative cadastrale : c’est le potentiel locatif du logement, dans l’hypothèse où celui-ci serait loué par le propriétaire.
      </StyledDescription>
      <HomeSectionDescription>
        À cette base de calcul, s’ajoute un taux déterminé chaque année par les collectivités territoriales. Pour le calculer, plusieurs paramètres sont pris en compte : surface du logement, état de l’appartement ou de la maison, environnement…
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default FiscalPropertyTax
