import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 830px;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const StyledDescription = styled(HomeSectionDescription)`
  margin-top: 16px;
`

const FiscalWealthTax = () => (
  <Container>
    <HomeSectionTitle>
      L’Impôt sur la Fortune Immobilière en cas de patrimoine immobilier
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      L’Impôt sur la Fortune Immobilière concerne uniquement les personnes ayant un patrimoine immobilier de 1,3 millions d’euros ou au-delà.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Autrement dit, seul le <strong>patrimoine immobilier entre en compte pour le calcul de l’impôt</strong>, ce qui n’était pas le cas anciennement avec l’Impôt de Solidarité sur la Fortune.
    </HomeSectionDescription>
    <StyledDescription>
      Plus concrètement, le patrimoine immobilier est imposable à partir de 800&nbsp;000&nbsp;€, en fonction d’un barème allant de 0% (800 000 €) à 1,5% (plus de 10&nbsp;000&nbsp;000&nbsp;€). Cette fiscalité de l’immobilier ne concerne donc qu’une petite partie des propriétaires français.
    </StyledDescription>
    <StyledDescription>
      À noter que l’Impôt sur la Fortune Immobilière peut être réduit par le biais de <strong>dons à des associations, ou bien via des placements pour la protection de forêts ou de vignobles</strong>, par exemple.
    </StyledDescription>
  </Container>
)

export default FiscalWealthTax
